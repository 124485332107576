import avatar from './lavon.png';
import logo from './lavon_logo.png';

import './App.css';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="logo" alt="Logo" />
        <img src={avatar} className="lavon" alt="Lavon" />
      </header>
    </div>
  );
}

export default App;
